/** General styles*/
.background {
  background-color: #171717;
}

/** Phones styles*/
@media(max-width: 576px) {

}

/** Landscape phones and tablets styles*/
@media (min-width: 576px) and (max-width: 992px) {

}

/** Landscape tablets and medium to large screens styles*/
@media (min-width: 992px) {

}