/** General styles*/
.amenitiesEsp{
  background-image: url('../../assets/img/amenidades.jpg');
}
.amenitiesEng{
  background-image: url('../../assets/img/amenities.jpg');
}
.alertsEsp{
  background-image: url('../../assets/img/alertas.jpg');
}
.alertsEng{
  background-image: url('../../assets/img/alerts.jpg');
}
.messagesEsp{
  background-image: url('../../assets/img/boletines.jpg');
}
.messagesEng{
  background-image: url('../../assets/img/message.jpg');
}
.paymentEsp{
  background-image: url('../../assets/img/pagos.jpg');
}
.paymentEng{
  background-image: url('../../assets/img/payments.jpg');
}
.visitsEsp{
  background-image: url('../../assets/img/visitas.jpg');
}
.visitsEng{
  background-image: url('../../assets/img/visits.jpg');
}
.workEsp{
  background-image: url('../../assets/img/trabajo.jpg');
}
.workEng{
  background-image: url('../../assets/img/work.jpg');
}
/** Phones styles*/
@media(max-width: 576px) {
  .fullVHDiv{
    margin-top: 5vh;
    height: 150vh;
    margin-bottom: 22vh;
  }
  .title{
    height: 5vh;
    width: 100%;
  }
  .title p{
    color: white;
    font-family: ArialBold;
  }
  .background{
    background-color: #252525;
    height: 88%;
    border-radius: 3%;
  }
  .image{
    background-image: url('../../assets/others/sala-min.png');
    background-repeat: no-repeat;
    height: 15vh;
    width: 100%;
    background-size: cover;
    border-radius: 18px 18px 18px 18px;
  }
  .texts{
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
  }
  .texts p{
    margin-top: 2vh;
    margin-left: 5vh;
    color: white;
    font-family: ArialRegular;
  }
  .mainTitle p{
    margin-top: 6vh;
    margin-left: 5vh;
    color: white;
    font-family: ArialBold;
  }
  .paragraph p{
    margin-left: 5vh;
    margin-right: 5vh;
    margin-bottom: 7vh;
    color: #bcbec0;
    font-size: 13px;
    font-family: ArialRegular;
    text-align: justify;
  }
  .amenities{
    margin-bottom: 2vh;
    background-repeat: no-repeat;
    background-size: cover;
    height: 21vh;
    width: 80%;
    border-radius: 10px;
    margin-left: 5vh;
  }
  .alerts{
    margin-left: 5vh;
    background-repeat: no-repeat;
    background-size: cover;
    height: 21vh;
    width: 78%;
    border-radius: 10px;
  }
  .messages{
    margin-left: 2vh;
    background-repeat: no-repeat;
    background-size: cover;
    height: 21vh;
    width: 71%;
    border-radius: 10px;
  }
  .payment{
    margin-left: 5vh;
    margin-top: 2vh;
    background-repeat: no-repeat;
    background-size: cover;
    height: 32vh;
    width: 78%;
    border-radius: 10px;
  }
  .visits{
    margin-top: 2vh;
    margin-left: 2vh;
    margin-bottom: 2vh;
    background-repeat: no-repeat;
    background-size: cover;
    height: 15vh;
    width: 72%;
    border-radius: 10px;
  }
  .work{
    margin-left: 2vh;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: cover;
    height: 15vh;
    width: 72%;
    border-radius: 10px;
    background-position: 25%;

  }
}

/** Landscape phones and tablets styles*/
@media (min-width: 576px) and (max-width: 1024px) {
  .fullVHDiv{
    margin-top: 5vh;
    height: 110vh;
    margin-bottom: 10vh;
  }
  .title{
    height: 5vh;
    width: 100%;
  }
  .title p{
    color: white;
    font-family: ArialBold;
  }
  .background{
    background-color: #252525;
    height: 80vh;
    border-radius: 3%;
  }
  .image{
    background-image: url('../../assets/others/sala-min.png');
    background-repeat: no-repeat;
    height: 30vh;
    width: 100%;
    background-size: cover;
    border-radius: 0% 0% 25px 25px;
  }
  .texts{
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
  }
  .texts p{
    margin-top: 2vh;
    margin-left: 5vh;
    color: white;
    font-family: ArialRegular;
  }
  .mainTitle p{
    margin-top: 6vh;
    margin-left: 3vh;
    color: white;
    font-family: ArialBold;
    font-size: 21px;
  }
  .paragraph p{
    margin-left: 3vh;
    margin-right: 3vh;
    margin-bottom: 7vh;
    color: #bcbec0;
    font-size: 13px;
    font-family: ArialRegular;
    text-align: justify;
  }
  .amenities{
    margin-bottom: 2vh;
    margin-left: 3vh;
    background-repeat: no-repeat;
    background-size: cover;
    height: 17vh;
    width: 82%;
    border-radius: 10px;
  }
  .alerts{
    margin-left: 3vh;
    background-repeat: no-repeat;
    background-size: cover;
    height: 17vh;
    width: 78%;
    border-radius: 10px;
  }
  .messages{
    margin-left: 2vh;
    background-repeat: no-repeat;
    background-size: cover;
    height: 17vh;
    width: 71%;
    border-radius: 10px;
  }
  .payment{
    background-repeat: no-repeat;
    background-size: cover;
    height: 36vh;
    width: 100%;
    border-radius: 10px;
  }
  .visits{
    margin-left: 2vh;
    margin-bottom: 2vh;
    background-repeat: no-repeat;
    background-size: cover;
    height: 17vh;
    width: 72%;
    border-radius: 10px;
  }
  .work{
    margin-left: 2vh;
    background-repeat: no-repeat;
    background-size: cover;
    height: 17vh;
    width: 72%;
    border-radius: 10px;
    background-position: 25%;

  }
}

/** Landscape tablets and medium to large screens styles*/
@media (min-width: 1025px) {
  .fullVHDiv{
    margin-top: 5vh;
    height: 135vh;
    margin-bottom: 10vh;
  }
  .title{
    height: 5vh;
    width: 100%;
  }
  .title p{
    color: white;
    font-family: ArialBold;
  }
  .background{
    background-color: #252525;
    height: 100vh;
    border-radius: 3%;
  }
  .image{
    background-image: url('../../assets/others/sala-min.png');
    background-repeat: no-repeat;
    height: 30vh;
    width: 100%;
    background-size: cover;
    border-radius: 0% 0% 25px 25px;
  }
  .texts{
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
  }
  .texts p{
    margin-top: 2vh;
    margin-left: 5vh;
    color: white;
    font-family: ArialRegular;
  }
  .mainTitle p{
    margin-top: 6vh;
    margin-left: 10vh;
    color: white;
    font-family: ArialBold;
    font-size: 21px;
  }
  .paragraph p{
    margin-left: 10vh;
    margin-right: 10vh;
    margin-bottom: 7vh;
    color: #bcbec0;
    font-size: 13px;
    font-family: ArialRegular;
    text-align: justify;
  }
  .amenities{
    margin-bottom: 2vh;
    margin-left: 10vh;
    background-repeat: no-repeat;
    background-size: cover;
    height: 34vh;
    width: 80%;
    border-radius: 10px;
  }
  .alerts{
    margin-left: 10vh;
    background-repeat: no-repeat;
    background-size: cover;
    height: 34vh;
    width: 78%;
    border-radius: 10px;
  }
  .messages{
    margin-left: 6vh;
    background-repeat: no-repeat;
    background-size: cover;
    height: 34vh;
    width: 71%;
    border-radius: 10px;
  }
  .payment{
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    width: 100%;
    border-radius: 10px;
  }
  .visits{
    margin-left: 2vh;
    margin-bottom: 2vh;
    background-repeat: no-repeat;
    background-size: cover;
    height: 34vh;
    width: 72%;
    border-radius: 10px;
  }
  .work{
    margin-left: 2vh;
    background-repeat: no-repeat;
    background-size: cover;
    height: 34vh;
    width: 72%;
    border-radius: 10px;
    background-position: 25%;
  }
}