/** General styles*/

/** Phones styles*/
@media(max-width: 576px) {
  .fullVHDiv{
    height: 90vh;
    width: 100vw;
    margin-left: -15px;
  }
  .mainAdminImageEsp{
    background-image: url('../../assets/img/admin-esp.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 41vh;
    width: 100%;
  }
  .mainAdminImageEng{
    background-image: url('../../assets/img/admin_ing.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 41vh;
    width: 100%;
  }
  .mainAppImageEsp{
    background-image: url('../../assets/img/app-esp.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 41vh;
    width: 100%;
  }
  .mainAppImageEng{
    background-image: url('../../assets/img/app_ing.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 41vh;
    width: 100%;
  }
  .selectedText{
    display: flex;
    align-items: center;
    margin-bottom: 2vh;
  }
  .selectedText p{
    margin: auto;
    color: white;
    font-family: ArialRegular;
  }
  .hide p{
    color: #171717;
  }
  .adminImage{
    background-image: url('../../assets/img/min_admin.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 21vh;
    width: 100%;
  }
  .appImage{
    background-image: url('../../assets/img/min_phone.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 21vh;
    width: 100%;
  }
  .textsContainer{
    margin-top: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .textsContainer p{
    margin: auto;
    color: #d1d3d4;
    font-size: 10px;
  }
}

/** Landscape phones and tablets styles*/
@media (min-width: 576px) and (max-width: 992px) {
  .fullVHDiv{
    height: 50vh;
  }
  .mainAdminImageEsp{
    background-image: url('../../assets/img/admin-esp.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 50vh;
    width: 110%;
  }
  .mainAdminImageEng{
    background-image: url('../../assets/img/admin_ing.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 50vh;
    width: 110%;
    margin-bottom: 5vh;
  }
  .mainAppImageEsp{
    background-image: url('../../assets/img/app-esp.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 50vh;
    width: 110%;
    margin-bottom: 5vh;
  }
  .mainAppImageEng{
    background-image: url('../../assets/img/app_ing.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 50vh;
    width: 110%;
    margin-bottom: 5vh;
  }
  .selectedText{
    margin-top: 7vh;
    display: flex;
    align-items: center;
    margin-bottom: 2vh;
  }
  .selectedText p{
    margin: auto;
    color: white;
    font-family: ArialRegular;
  }
  .hide{
    margin-top: 7vh;
    display: flex;
    align-items: center;
    margin-bottom: 2vh;
  }
  .hide p{
    color: #171717;
    margin: auto;
  }
  .adminImage{
    background-image: url('../../assets/img/min_admin.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 10vh;
    width: 87%;
    cursor: pointer;
  }
  .appImage{
    background-image: url('../../assets/img/min_phone.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 10vh;
    width: 87%;
    cursor: pointer;
  }
  .textsContainer{
    margin-top: 3vh;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .textsContainer p{
    margin: auto;
    color: #d1d3d4;
  }
}

/** Landscape tablets and medium to large screens styles*/
@media (min-width: 992px) {
  .fullVHDiv{
    height: 75vh;
  }
  .mainAdminImageEsp{
    background-image: url('../../assets/img/admin-esp.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 78vh;
    width: 110%;
  }
  .mainAdminImageEng{
    background-image: url('../../assets/img/admin_ing.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 78vh;
    width: 110%;
  }
  .mainAppImageEsp{
    background-image: url('../../assets/img/app-esp.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 78vh;
    width: 110%;
  }
  .mainAppImageEng{
    background-image: url('../../assets/img/app_ing.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 78vh;
    width: 110%;
  }
  .logo{
    background-image: url('../../assets/icons/houselive_logo.png');
    background-repeat: no-repeat;
    width: 100%;
    background-size: 100%;
    height: 20vh;
  }
  .selectedText{
    margin-top: 5vh;
    display: flex;
    align-items: center;
    margin-bottom: 2vh;
  }
  .selectedText p{
    margin: auto;
    color: white;
    font-family: ArialRegular;
  }
  .hide{
    margin-top: 5vh;
    display: flex;
    align-items: center;
    margin-bottom: 2vh;
  }
  .hide p{
    color: #171717;
    margin: auto;
  }
  .adminImage{
    background-image: url('../../assets/img/min_admin.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 26vh;
    width: 87%;
    cursor: pointer;
  }
  .appImage{
    background-image: url('../../assets/img/min_phone.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 26vh;
    width: 87%;
    cursor: pointer;
  }
  .textsContainer{
    margin-top: 3vh;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .textsContainer p{
    margin: auto;
    color: #d1d3d4;
  }
}