/** General styles*/
.noPad {
  padding: 0;
}
.diffEffect {
  height: 100px;
  background-color: transparent;
  background-image: linear-gradient(transparent, black);
}
.fullVHDiv{
  height: 100vh;
}
/** Phones styles*/
@media(max-width: 576px) {
  .background {
    background-image: linear-gradient(180deg, rgba(255,255,255,0) 65%, rgba(23,23,23,1) 98%, rgba(23,23,23,1) 100%), url('../../assets/img/responsive.jpg');
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .title {
    height: 27vh;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .title p {
    font-size: 13px;
    color: white;
    margin-top: 13vh;
    margin-left: auto;
    margin-right: auto;
    font-family: ArialRegular;
    word-spacing: 5px;
    letter-spacing: 7px;
  }
  .mainLogo img{
    width: 100%;
  }
  .mediaButtons {
    height: 90px;
    vertical-align: middle;
    align-items: center;
    display: flex;
    margin: auto;
  }
  .mediaButtons a {
    margin: auto;
  }
  .mediaButtons img {
    max-height: 17px;
  }
  .exploreLine {
    height: 80px;
    margin-top: 7vh;
    margin-bottom: 10px;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .exploreLine img {
    max-height: 56%;
    margin: auto;
  }
  .exploreText, .exploreButton {
    height: 30px;
    margin-bottom: 10px;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .exploreText p {
    margin: auto;
    color: white;
    font-size: 14px;
    font-family: ArialRegular;
  }
  .exploreButton a {
    margin: auto;
  }
  .exploreButton a img {
    max-height: 22px;
  }
  .mediaContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

/** Landscape phones and tablets styles*/
@media (min-width: 576px) and (max-width: 992px) {
  .background {
    background-image: linear-gradient(180deg, rgba(255,255,255,0) 65%, rgba(23,23,23,1) 98%, rgba(23,23,23,1) 100%), url('../../assets/img/home_2.jpg');
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .title {
    height: 23vh;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .title p {
    font-size: 20px;
    color: white;
    margin: auto;
    margin-bottom: 0;
    font-family: ArialRegular;
    word-spacing: 10px;
    letter-spacing: 5px;
  }
  .mediaButtons {
    height: 90px;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .mediaButtons a {
    margin: auto;
  }
  .mediaButtons img {
    max-height: 17px;
    margin-top: 20vh;
  }
  .mainLogo {
    height: 50%;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .mainLogo img {
    max-height: 40px;
    margin: auto;
  }
  .exploreLine {
    height: 80px;
    margin-top: 16vh;
    margin-bottom: 10px;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .exploreLine img {
    max-height: 56%;
    margin: auto;
  }
  .exploreText, .exploreButton {
    height: 30px;
    margin-bottom: 10px;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .exploreText p {
    margin: auto;
    color: white;
    font-size: 14px;
    font-family: ArialRegular;
  }
  .exploreButton a {
    margin: auto;
  }
  .exploreButton a img {
    max-height: 22px;
  }
  .leftText, .rightText {
    height: 100%;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .leftText p {
    margin-top: auto;
    margin-right: auto;
    margin-left: 20%;
    margin-bottom: 50px;
    font-size: 14px;
    color: white;
    font-family: ArialRegular;
  }
  .rightText p {
    margin-top: auto;
    margin-left: auto;
    margin-right: 20%;
    margin-bottom: 50px;
    font-size: 14px;
    color: white;
    font-family: ArialRegular;
  }
}

/** Landscape tablets and medium to large screens styles*/
@media (min-width: 992px) {
  .background {
    background-image: linear-gradient(180deg, rgba(255,255,255,0) 65%, rgba(23,23,23,1) 98%, rgba(23,23,23,1) 100%), url('../../assets/img/home_test.jpg');
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .title {
    height: 23vh;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .title p {
    font-size: 20px;
    color: white;
    margin: auto;
    margin-bottom: 0;
    font-family: ArialRegular;
    word-spacing: 10px;
    letter-spacing: 5px;
  }
  .mediaContainer{
    margin-top: 20vh;
  }
  .mediaButtons {
    height: 90px;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .mediaButtons a {
    margin: auto;
  }
  .mediaButtons img {
    max-height: 17px;
  }
  .mainLogo {
    height: 50%;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .mainLogo img {
    max-height: 40px;
    margin: auto;
  }
  .exploreLine {
    height: 80px;
    margin-top: 3vh;
    margin-bottom: 10px;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .exploreLine img {
    max-height: 56%;
    margin: auto;
  }
  .exploreText, .exploreButton {
    height: 30px;
    margin-bottom: 10px;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .exploreText p {
    margin: auto;
    color: white;
    font-size: 14px;
    font-family: ArialRegular;
    word-spacing: 5px;
  }
  .exploreButton a {
    margin: auto;
  }
  .exploreButton a img {
    max-height: 22px;
    cursor: pointer;
  }
  .leftText, .rightText {
    height: 100%;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .leftText p {
    margin-top: auto;
    margin-right: auto;
    margin-left: 20%;
    margin-bottom: 50px;
    font-size: 14px;
    color: white;
    font-family: ArialRegular;
  }
  .rightText p {
    margin-top: auto;
    margin-left: auto;
    margin-right: 20%;
    margin-bottom: 50px;
    font-size: 14px;
    color: white;
    font-family: ArialRegular;
  }
  
}