/** General styles*/
.navRow {
  border-bottom: 0.5px solid white;
}
.hide{
  display: none;
}

/** Phones styles*/
@media(max-width: 576px) {
  .logoImg {
    height: 100px;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .logoImg img {
    max-width: 100px;
    margin: auto;
  }
  .downIcon {
    height: 100px;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .downIcon img {
    max-width: 26px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: 0;
    cursor: pointer;
  }
  .dropdownContainer {
    position: relative;
    top: 100px;
  }
  .dropdownContainer div {
    position: absolute;
    height: 5vh;
    width: 100vw;
    background-color: #678296;
    right: 0;
    font-size: 16px;
    font-weight: 500;
    color: white;
  }
  .itemOne div{
    top: 0vh;
    z-index: 9;
  }
  .itemTwo div {
    top: 5vh;
    z-index: 9;
  }
  .itemThree div{
    top: 10vh;
    z-index: 9;
  }
  .itemFour div{
    top: 15vh;
    z-index: 9;
  }
  .itemFive div{
    top: 20vh;
    z-index: 9;
  }
  .dropdownContainer div:nth-child(6) {
    top: 25vh;
    z-index: 9;
    padding-left: 5vw;
  }
  .dropdownContainer div:nth-child(7) {
    top: 25vh;
    z-index: 9;
    padding-left: 5vw;
  }
  .dropdownContainer .navItem{
    width: 100vw;
  }
  .dropdownContainer .navItem a{
    width: 100vw;
  }
  .dropdownContainer .navItem p {
    padding-left: 5vw;
    margin: auto;
    height: 100%;
    width: 100%;
  }
  .navLanguageItem button {
    margin-left: 5vw;
    border-radius: 50px;
    border-color: white;
    margin: auto;
    background-color: transparent;
    color: white;
    border-style: solid;
    border-width: 1px;
    font-size: 13px;
  }
}

/** Landscape phones and tablets styles*/
@media (min-width: 576px) and (max-width: 992px) {
  .logoImg {
    height: 100px;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .logoImg img {
    max-width: 125px;
    margin: auto;
  }
  .navItem {
    height: 100px;
    vertical-align: middle;
    align-items: center;
    display: flex;
    font-size: 0.4rem;
    color: white;
    cursor: pointer;
  }
  .navItem:hover {
    border-bottom: 2px solid white;
  }
  .navItem a {
    margin: auto;
  }
  .navLanguageItem {
    height: 100px;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .navLanguageItem button {
    border-radius: 50px;
    border-color: white;
    margin: auto;
    background-color: transparent;
    color: white;
    border-style: solid;
    border-width: 1px;
    font-size: 13px;
  }
}

/** Landscape tablets and medium to large screens styles*/
@media (min-width: 992px) {
  .logoImg {
    height: 100px;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .logoImg img {
    max-width: 125px;
    margin: auto;
  }
  .navItem {
    height: 100px;
    vertical-align: middle;
    align-items: center;
    display: flex;
    font-size: 13px;
    color: white;
    cursor: pointer;
  }
  .navItem:hover {
    border-bottom: 2px solid white;
  }
  .navItem p {
    margin: auto;
  }
  .navLanguageItem {
    height: 100px;
    vertical-align: middle;
    align-items: center;
    display: flex;
  }
  .navLanguageItem button {
    border-radius: 50px;
    border-color: white;
    margin: auto;
    background-color: transparent;
    color: white;
    border-style: solid;
    border-width: 1px;
    font-size: 13px;
  }
}
