/** General styles*/

/** Phones styles*/
@media(max-width: 576px) {
  .fullVHDiv{
    margin-top: 5vh;
    height: 35vh;
  }
  .textContainer{
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .textContainer p{
    color: #58595b;
    margin: auto;
    font-family: ArialRegular;
    font-size: 12px;
  }
  .iconContainer{
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .iconContainer a{
    margin: auto;
  }
  .iconContainer a img{
    height: 20px;
    width: 20px;
  }
}

/** Landscape phones and tablets styles*/
@media (min-width: 576px) and (max-width: 992px) {
  .fullVHDiv{
    height: 20vh;
  }
  .textContainer{
    height: 20vh;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .textContainer p{
    color: #d1d3d4;
    margin: auto;
    font-size: 15px;
    font-family: ArialRegular;
  }
  .iconContainer{
    height: 20vh;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .iconContainer a{
    margin: auto;
  }
  .iconContainer a img{
    height: 20px;
    width: 20px;
  }
}

/** Landscape tablets and medium to large screens styles*/
@media (min-width: 992px) {
  .fullVHDiv{
    height: 20vh;
  }
  .textContainer{
    height: 20vh;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .textContainer p{
    color: #d1d3d4;
    margin: auto;
    font-size: 15px;
    font-family: ArialRegular;
  }
  .iconContainer{
    height: 20vh;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .iconContainer a{
    margin: auto;
  }
  .iconContainer a img{
    height: 20px;
    width: 20px;
  }
}