/** General styles*/

::placeholder{
  color: white;
  font-family: ArialRegular;
}

/** Phones styles*/
@media(max-width: 576px) {
  .background{
    background-image: url('../../assets/img/conctact_responsive.jpg');
    background-size: cover;
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    position: absolute;
    border-radius: 25px;
  }
  .sendButton{
    background: none;
    margin: auto;
    border-width: 1px;
    border-style: solid;
    color: white;
    border-color: white;
    outline: none;
    width: 43%;
    height: 4vh;
    margin-left: 53%;
    font-family: ArialRegular;
    font-size: 13px;
    border-radius: 5px;
  }
  .fullVHDiv{
    margin-top: 5vh;
    height: 100vh;
    padding-left: -15px;
    padding-right: -15px;
  }
  .contact{
    margin-top: 3vh;
    color: white;
  }
  .container{
    display: flex;
    flex-direction: row;
  }
  .title{
    font-size: 4vh;
    font-family: ArialBold;
    margin-bottom: 4vh;
    color: white;
  }
  .iconsContainer{
    height: 80vh;
    width: 23%;
    display: none;
  }
  .iconLogoContainer{
    width:100%;
    height: 20vh;
    display: flex;
    align-items: center;
  }
  .logo{
    width: 13%;
    margin: auto;
  }
  .iconArrowContainer{
    width:100%;
    height: 20vh;
    display: flex;
    align-items: center;
    margin-top: 40vh;
  }
  .arrow{
    width: 55%;
    margin: auto;
    cursor: pointer;
    margin-left: 2vh;
    transform: rotate(180deg);
  }
  .iconInstagramContainer{
    margin-top: 66vh;
    width:100%;
    height: 10vh;
    display: flex;
    align-items: center;
    margin-left: 1vh;
  }
  .instagram{
    width: 20%;
    margin: auto;
  }
  .iconFacebookContainer{
    width:100%;
    height: 10vh;
    display: flex;
    align-items: center;
  }
  .facebook{
    width: 20%;
    margin: auto;
  }
  .textContainer{
    margin-left: 1vw;
    height: 40vh;
    width: 100%
  }
  .vinetas p{
    color: #dddddd;
    font-size: 13px;
    font-family: ArialRegular;
  }
  .vineta{
    display: flex;
    flex-direction: row;
  }
  .point{
    margin-right: 2vh;
  }
  .detail{
    border-bottom-width: 2px;
    border-color: white;
    border-bottom-style: solid;
    width:10%;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
  .inputContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
    margin-right: 1vh;
  }
  .input{
    color: white;
    margin: auto;
    margin-left: 0;
    margin-bottom: 20%;
    background: none;
    width: 100%;
    outline: none;
    border-width: 0 0 1px;
    border-color: white;
    font-family: ArialRegular;
    font-size: 12px;
    padding-bottom: 8px;
  }
  .textarea{
    color: white;
    margin: auto;
    margin-left: 0;
    margin-bottom: 10%;
    background: none;
    width: 100%;
    outline: none;
    border-width: 0 0 1px;
    border-color: white;
    font-family: ArialRegular;
    font-size: 12px;
    resize: none;
  }
  .completedMesssage{
    height: 3vh;
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
  }
  .completedMesssage p{
    color: #b8d670;
    font-size: 13px;
  }
  .check{
    background-image: url('../../assets/icons/check_green.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 15px;
    width: 15px;
    margin-left: 5px;
  }

}

/** Landscape phones and tablets styles*/
@media (min-width: 576px) and (max-width: 1024px) {
  .fullVHDiv{
    height: 94vh;
    padding-left: -15px;
    padding-right: -15px;
  }
  .background{
    background-image: url('../../assets/img/contact_us.png');
    background-size: 100% 100%;
    height: 80vh;
    margin-top: 10vh;
    width: 100%;
    background-repeat: no-repeat;
    position: absolute;
    border-radius: 25px;
  }
  .contact{
    margin-top: 15vh;
    color: white;
  }
  .blur{
    position: absolute;
    background-image: url('../../assets/img/blur.jpg');
    background-size: 100% 100%;
    height: 80vh;
    width: 100%;
    background-repeat: no-repeat;
  }
  .container{
    display: flex;
    flex-direction: row;
  }
  .title{
    font-size: 3vh;
    font-family: ArialBold;
    margin-bottom: 5vh;
    color: white;
  }
  .iconsContainer{
    height: 80vh;
    width: 23%;
  }
  .iconLogoContainer{
    width:100%;
    height: 20vh;
    display: flex;
    align-items: center;
  }
  .logo{
    width: 13%;
    margin: auto;
  }
  .iconArrowContainer{
    width:100%;
    height: 20vh;
    display: flex;
    align-items: center;
    margin-top: 40vh;
  }
  .arrow{
    width: 55%;
    margin: auto;
    cursor: pointer;
    margin-left: 2vh;
    transform: rotate(180deg);
  }
  .iconInstagramContainer{
    margin-top: 66vh;
    width:100%;
    height: 10vh;
    display: flex;
    align-items: center;
  }
  .instagram{
    width: 20%;
    margin: auto;
  }
  .iconFacebookContainer{
    width:100%;
    height: 10vh;
    display: flex;
    align-items: center;
  }
  .facebook{
    width: 20%;
    margin: auto;
  }
  .textContainer{
    margin-left: 1vw;
    height: 80vh;
    width: 77%
  }
  .vinetas p{
    color: #dddddd;
    font-family: ArialRegular;
  }
  .vineta{
    display: flex;
    flex-direction: row;
  }
  .point{
    margin-right: 5vh;
  }
  .detail{
    border-bottom-width: 2px;
    border-color: white;
    border-bottom-style: solid;
    width:10%;
    margin-top: 4vh;
    margin-bottom: 4vh;
  }
  .inputContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
    margin-left: 6vh;
    margin-right: 3vh;
  }
  .input{
    color: white;
    margin: auto;
    margin-left: 0;
    margin-bottom: 40%;
    background: none;
    width: 100%;
    outline: none;
    border-width: 0 0 1px;
    border-color: white;
    font-family: ArialRegular;
    font-size: 11px;
    padding-bottom: 15px;
  }
  .textarea{
    color: white;
    margin: auto;
    margin-left: 0;
    margin-bottom: 20%;
    background: none;
    width: 100%;
    outline: none;
    border-width: 0 0 1px;
    border-color: white;
    font-family: ArialRegular;
    font-size: 11px;
    resize: none;
  }
  .inputTop{
    margin-top: 20vh;
  }
  .sendButton{
    background: none;
    margin: auto;
    border-width: 1px;
    border-style: solid;
    color: white;
    border-color: white;
    outline: none;
    width: 35%;
    height: 5vh;
    margin-left: 55%;
    font-family: ArialRegular;
    font-size: 13px;
    border-radius: 5px;
  }
  .completedMesssage{
    height: 3vh;
    margin-left: 6vh;
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
  }
  .completedMesssage p{
    color: #b8d670;
    font-size: 13px;
  }
  .check{
    background-image: url('../../assets/icons/check_green.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 15px;
    width: 15px;
    margin-left: 5px;
  }
}

/** Landscape tablets and medium to large screens styles*/
@media (min-width: 1025px) {
  .fullVHDiv{
    height: 94vh;
    padding-left: -15px;
    padding-right: -15px;
  }
  .background{
    background-image: url('../../assets/img/contact_us.png');
    background-size: cover;
    height: 80vh;
    margin-top: 10vh;
    width: 100%;
    background-repeat: no-repeat;
    position: absolute;
    border-radius: 25px;
  }
  .contact{
    margin-top: 15vh;
    color: white;
  }
  .blur{
    position: absolute;
    background-image: url('../../assets/img/blur.jpg');
    background-size: 100% 100%;
    height: 80vh;
    width: 100%;
    background-repeat: no-repeat;
  }
  .container{
    display: flex;
    flex-direction: row;
  }
  .title{
    font-size: 6vh;
    font-family: ArialBold;
    margin-bottom: 5vh;
    color: white;
  }
  .iconsContainer{
    height: 80vh;
    width: 23%;
  }
  .iconLogoContainer{
    width:100%;
    height: 20vh;
    display: flex;
    align-items: center;
  }
  .logo{
    width: 13%;
    margin: auto;
  }
  .iconArrowContainer{
    width:100%;
    height: 20vh;
    display: flex;
    align-items: center;
    margin-top: 40vh;
  }
  .arrow{
    width: 55%;
    margin: auto;
    cursor: pointer;
    margin-left: 2vh;
    transform: rotate(180deg);
  }
  .iconInstagramContainer{
    margin-top: 66vh;
    width:100%;
    height: 10vh;
    display: flex;
    align-items: center;
    margin-left: 1vh;
  }
  .instagram{
    width: 20%;
    margin: auto;
  }
  .iconFacebookContainer{
    width:100%;
    height: 10vh;
    display: flex;
    align-items: center;
  }
  .facebook{
    width: 20%;
    margin: auto;
  }
  .textContainer{
    margin-left: 1vw;
    height: 80vh;
    width: 77%
  }
  .vinetas p{
    color: #dddddd;
    font-family: ArialRegular;
  }
  .vineta{
    display: flex;
    flex-direction: row;
  }
  .point{
    margin-right: 5vh;
  }
  .detail{
    border-bottom-width: 2px;
    border-color: white;
    border-bottom-style: solid;
    width:10%;
    margin-top: 4vh;
    margin-bottom: 4vh;
  }
  .inputContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
    margin-left: 6vh;
    margin-right: 3vh;
  }
  .input{
    color: white;
    margin: auto;
    margin-left: 0;
    margin-bottom: 20%;
    background: none;
    width: 100%;
    outline: none;
    border-width: 0 0 1px;
    border-color: white;
    font-family: ArialRegular;
    font-size: 11px;
    padding-bottom: 15px;
  }
  .textarea{
    color: white;
    margin: auto;
    margin-left: 0;
    margin-bottom: 5%;
    background: none;
    width: 100%;
    outline: none;
    border-width: 0 0 1px;
    border-color: white;
    font-family: ArialRegular;
    font-size: 11px;
    resize: none;
  }
  .inputTop{
    margin-top: 10vh;
  }
  .sendButton{
    background: none;
    margin: auto;
    border-width: 1px;
    border-style: solid;
    color: white;
    border-color: white;
    outline: none;
    width: 35%;
    height: 5vh;
    margin-left: 55%;
    font-family: ArialRegular;
    font-size: 13px;
    border-radius: 5px;
  }
  .completedMesssage{
    height: 3vh;
    margin-left: 6vh;
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
  }
  .completedMesssage p{
    color: #b8d670;
    font-size: 13px;
  }
  .check{
    background-image: url('../../assets/icons/check_green.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 15px;
    width: 15px;
    margin-left: 5px;
  }
}