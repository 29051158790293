/** General styles*/

/** Phones styles*/
@media(max-width: 576px) {
  .title {
    height: 56px;
    text-align: left;
  }
  .title p {
    font-size: 2rem;
    color: white;
    /* font-weight: bold; */
    font-family: ArialBold;
  }
  .aboutImage img{
    width:100%;
  }
  .roundTitle {
    margin-top: 90%;
    height: 30px;
    width: 80%;
    border: 1px solid white;
    border-radius: 50px;
    display: flex;
    align-items: center;
    vertical-align: middle;
  }
  .roundTitle p {
    color: white;
    margin: auto;
    font-family: ArialRegular;
    font-size: 12px;
  }
  .subTitle {
    height: 154px;
    display: flex;
    align-items: center;
  }
  .subTitle p {
    font-size: 30px;
    text-align: center;
    color: #eeedee;
    font-family: ArialBold;
    margin: auto;
  }
  .imgContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    vertical-align: middle;
  }
  .imgContainer img {
    max-width: 100%;
  }
  .bulletText {
    text-align: center;
    margin-top: 10px;
  }
  .bulletText p {
    color: #e2e1e7;
    font-size: 1rem;
    font-family: ArialRegular;
  }
}

/** Landscape phones and tablets styles*/
@media (min-width: 576px) and (max-width: 992px) {
  .fullVHDiv{
    margin-top: 5vh;
    height: 52vh;
  }
  .title {
    height: 80px;
    text-align: left;
  }
  .title p {
    font-size: 1rem;
    color: white;
    font-weight: bold;
  }
  .aboutImage {
    height: 100%;
    width: 100%;
  }
  .aboutImage img {
    max-width: 100%;
  }
  .roundTitle {
    margin-top: 90%;
    height: 30px;
    width: 80%;
    border: 1px solid white;
    border-radius: 50px;
    display: flex;
    align-items: center;
    vertical-align: middle;
  }
  .roundTitle p {
    font-size: 10px;
    color: white;
    margin: auto;
  }
  .subTitle {
    height: 160px;
  }
  .subTitle p {
    font-size: 2rem;
    color: white;
    font-weight: 500;
  }
  .imgContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    vertical-align: middle;
  }
  .imgContainer img {
    max-width: 100%;
  }
  .bulletText {
    text-align: center;
    margin-top: 10px;
  }
  .bulletText p {
    color: white;
    font-size: 1rem;
  }
}

/** Landscape tablets and medium to large screens styles*/
@media (min-width: 992px) {
  .fullVHDiv{
    margin-top: 5vh;
    height: 58vh;
  }
  .title {
    height: 56px;
    text-align: left;
  }
  .title p {
    font-size: 2rem;
    color: white;
    /* font-weight: bold; */
    font-family: ArialBold;
  }
  .aboutImage {
    height: 100%;
    width: 100%;
  }
  .aboutImage img {
    max-width: 100%;
  }
  .roundTitle {
    margin-top: 77%;
    height: 25px;
    width: 80%;
    border: 1px solid white;
    border-radius: 50px;
    display: flex;
    align-items: center;
    vertical-align: middle;
  }
  .roundTitle p {
    color: white;
    margin: auto;
    font-family: ArialRegular;
    font-size: 10px;
  }
  .subTitle {
    margin-top: 2vh;
    height: 138px;
  }
  .subTitle p {
    font-size: 35px;
    color: #eeedee;
    /* font-weight: 500; */
    font-family: ArialBold;
  }
  .imgContainer {
    margin-right: 5vh;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    vertical-align: middle;
  }
  .imgContainer img {
    width: 87%;
  }
  .bulletText {
    text-align: center;
    margin-top: 10px;
  }
  .bulletText p {
    color: #e2e1e7;
    font-size: 1rem;
    font-family: ArialRegular;
  }
}